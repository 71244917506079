<template>
    <div class="contain">
        <bills-card v-for="(survey, key) in data" :key="`link${key}`" :card="survey" class="bills_card"/>
    </div>
</template>
<script>
import BillsCard from '@/components/activitiesSurveys/BillsCard'

export default {
  name: 'BillsBlock',
  components: {
    'bills-card': BillsCard
  },
  props: {
    data: {
      type: Array
    }
  }
}
</script>
<style lang="scss" scoped>
.contain{
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.06rem;
}
.bills_card{
    margin: 0 1.06rem 1.87rem 1.06rem;
    width: calc(33.333% - 2.125rem);
}
@media screen and (max-width: 768px){
  .bills_card{
    width: calc(50% - 2.125rem);
  }
}
@media screen and (max-width: 420px){
  .bills_card{
    width: calc(100% - 2.125rem);
  }
}
</style>
