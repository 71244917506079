<template>
  <div>
    <bread-crumb :navigationArray="navigationLink"/>
    <div class="white-container">
      <h2>Общественная экспертиза</h2>
      <tricolor-line class="tricolor_line"/>
      <div class="panel__tabs">
        <p v-for="(tab, index) in tabs" :class="{'active' : isActiveTab === index}" :key="`tab${index}`"
              @click="setTabNews(tab.route)">{{ tab.name }}</p>
      </div>
      <bills-block :data="bills"/>
      <pagination-block class="news__pagination" :current="currentPage" :total="countPage" @page-changed="getPage"/>
    </div>
  </div>
</template>
<script>
import BreadCrumb from '@/components/BreadCrumb'
import TricolorLine from '@/components/TricolorLine'
import BillsBlock from '@/components/activitiesSurveys/BillsBlock'
import Pagination from '@/components/Pagination'

export default {
  name: 'PublicExaminationBills',
  components: {
    'bread-crumb': BreadCrumb,
    'tricolor-line': TricolorLine,
    'bills-block': BillsBlock,
    'pagination-block': Pagination
  },
  data () {
    return {
      currentPage: 0,
      isActiveTab: 1,
      countPage: 50,
      navigationLink: [
        {
          title: 'Главная',
          path: '/'
        },
        {
          title: 'Деятельность',
          path: '#'
        },
        {
          title: 'Законопроекты для обсуждения'
        }
      ],
      tabs: [
        {
          name: 'Новости',
          route: '/public-examination'
        },
        {
          name: 'Законопроекты для обсуждения',
          route: '/public-examination-bills'
        },
        {
          name: 'Экспертные заключения',
          route: '/public-examination-opinion'
        }
      ],
      bills: [
        // {
        //   date: '01.01.2020',
        //   title: 'Законопроект о противодействии коррупции',
        //   text: 'Новый законопроект относится к противодействию коррупции на территории РФ и Новый законопроект относится',
        //   photo: 'hammer.svg',
        //   link: '/adopted_bill'
        // }
      ]
    }
  },
  mounted () {
    this.$store.dispatch(
      'setFilterBillDraft',
      { state: 'IN_REVIEW' }
    )
    document.title = 'Законопроекты для обсуждения'
  },
  computed: {
    getBillDraft () {
      return this.$store.getters.getBillDraft
    },
    getListView () {
      return this.$store.getters.getListView
    }
  },
  watch: {
    getBillDraft () {
      this.setBillDraft()
    }
  },
  methods: {
    getPage (page) {
      console.log(page)
      this.currentPage = page
    },
    setTabNews (route) {
      this.$router.push({ path: `${route}` })
    },
    setBillDraft () {
      this.bills.length = 0
      this.countPage = this.$store.getters.getBillDraft.total
      this.$store.getters.getBillDraft.forEach(item => {
        this.bills.push({
          ...item,
          link: `/adopted_bill/${item.id}`,
          photo: 'hammer.svg'
        })
      })
    }
  }
}
</script>
<style lang="scss" scoped>
  .white-container {
    margin: 0 3.8rem;
    padding: 2.5rem 2.94rem;
  }

  h2 {
    font-weight: 600;
    font-size: 1.38rem;
    line-height: 1.75rem;
    color: #1F3245;
    margin-bottom: 2rem;
  }

  h3 {
    font-weight: 600;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-bottom: 1.375rem;
    color: #1F3245;
  }

  .tricolor_line {
    margin-bottom: 2rem;
  }

  .panel__tabs {
    display: flex;
    padding-bottom: 1.375rem;
    border-bottom: .0625rem solid #D5D6D9;
    margin-bottom: 2rem;
    color: #003E78;

    p, .active {
      font-size: 0.8125rem;
      margin-right: 1.25rem;
      position: relative;
      height: 100%;
      display: flex;
      align-items: center;
      opacity: 0.6;

      cursor: pointer;
    }

    .active {
      font-weight: 600;
      color: #246CB7;
      opacity: 1;
    }

    .active::before {
      content: '';
      height: 100%;
      width: 100%;
      bottom: -1.45rem;
      position: absolute;
      border-bottom: 0.125rem solid #246CB7;
    }

  }

  .news__pagination {
    margin-top: 0.625rem;
    padding: 2.5rem 0 1.87rem 0;
    border-top: .0625rem solid #D5D6D9;
  }

  .button_block {
    display: flex;
  }

  @media screen and (max-width: 768px) {
    .white-container {
      width: auto;
      margin: 0 .75rem;
      padding: 1.86rem 1.25rem;
    }
  }

  @media screen and (max-width: 420px) {
    .white-container {
      width: 100%;
      margin: 0;
      padding: 1.88rem 1rem;
    }
    .panel__tabs {
      overflow-x: scroll;
      flex-wrap: nowrap;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      p{
        flex: 0 0 auto;
      }
      .active::before {
        content: '';
        height: 100%;
        width: 100%;
        bottom: -1.38rem;
      }
    }
    ::v-deep .contain{
      margin: 0;
      .bills_card{
        width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }
</style>
